import { useEffect, useState } from "react";
import List from "./components/List";
import Modal from "./components/Modal";

function App() {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(null);
  const [updateData, setNote] = useState(null);
  const [id, setId] = useState(null);

  const fetchNotes = () => {
    fetch("http://192.168.43.189:80")
      .then((res) => res.json())
      .then((data) => setData(data));
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const deleteNote = (id) => {
    if (id) {
      fetch(`http://192.168.43.189:80/deleteNote/${id}`, {
        method: "get",
      })
        .then((res) => res.json())
        .then((data) => {
          fetchNotes();
        });
    }
  };
  // const updateNote = (id, data) => {
  //   if (id) {

  // };
  const editNote = (id) => {
    setModal(true);
    setId(id);
    if (id) {
      fetch(`http://192.168.43.189:80/getNote/${id}`, {
        method: "get",
      })
        .then((res) => res.json())
        .then((data) => {
          setNote(data);
        });
    }
  };

  const closeModal = (payload) => {
    setModal(false);
    if (updateData && payload) {
      fetch(`http://192.168.43.189:80/updateNote/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchNotes();
          setNote(null);
        });
    }

    if (payload.title && payload.content && !updateData) {
      fetch("http://192.168.43.189:80/saveNote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchNotes();
        });
    }
  };

  return (
    <>
      <div
        className={`bg-slate-950 h-screen text-slate-200 p-4 duration-300 ${
          modal ? "scale-95 brightness-50" : "scale-100"
        }`}
      >
        <div className="bg-slate-900 rounded-xl px-5 py-2 mb-4 flex justify-between items-center">
          <h1 className="Paris">Notes</h1>
          <button onClick={() => setModal(true)}>
            <svg
              width="17"
              height="17"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 0C11.8812 0 12.2469 0.151451 12.5165 0.421034C12.786 0.690618 12.9375 1.05625 12.9375 1.4375V10.0625H21.5625C21.9437 10.0625 22.3094 10.214 22.579 10.4835C22.8486 10.7531 23 11.1188 23 11.5C23 11.8812 22.8486 12.2469 22.579 12.5165C22.3094 12.786 21.9437 12.9375 21.5625 12.9375H12.9375V21.5625C12.9375 21.9437 12.786 22.3094 12.5165 22.579C12.2469 22.8486 11.8812 23 11.5 23C11.1188 23 10.7531 22.8486 10.4835 22.579C10.214 22.3094 10.0625 21.9437 10.0625 21.5625V12.9375H1.4375C1.05625 12.9375 0.690618 12.786 0.421034 12.5165C0.151451 12.2469 0 11.8812 0 11.5C0 11.1188 0.151451 10.7531 0.421034 10.4835C0.690618 10.214 1.05625 10.0625 1.4375 10.0625H10.0625V1.4375C10.0625 1.05625 10.214 0.690618 10.4835 0.421034C10.7531 0.151451 11.1188 0 11.5 0Z"
                fill="#F4F4F4"
              />
            </svg>
          </button>
        </div>
        {data ? (
          <List data={data} deleteNote={deleteNote} editNote={editNote} />
        ) : null}
      </div>
      <Modal
        modal={modal}
        closeModal={closeModal}
        updateData={updateData ? updateData : null}
      />
    </>
  );
}

export default App;
