import { useEffect, useState } from "react";

function Modal({ modal, closeModal, updateData }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    if (updateData) {
      setTitle(updateData.title);
      setContent(updateData.content);
    }
  }, [updateData]);

  const handleCloseModal = () => {
    const payload = { title, content };
    closeModal(payload);
    setTitle("");
    setContent("");
  };

  return (
    <div
      className={`bg-slate-900 fixed w-screen top-0 left-0 text-sky-100 h-screen p-7 rounded-2xl duration-300 ${
        modal ? "translate-y-[10%]" : "translate-y-full"
      }`}
    >
      <div className="flex justify-between">
        <input
          type="text"
          className="bg-transparent focus:outline-none w-4/5"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <button onClick={handleCloseModal}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.16759 0.352323C1.67172 -0.117441 0.867766 -0.117441 0.371894 0.352323C-0.123965 0.8221 -0.123965 1.58374 0.371894 2.05352L7.70429 9.00002L0.371971 15.9465C-0.123889 16.4163 -0.123889 17.178 0.371971 17.6477C0.867843 18.1174 1.67179 18.1174 2.16765 17.6477L9.49996 10.7012L16.8323 17.6477C17.3282 18.1174 18.1322 18.1174 18.628 17.6477C19.1238 17.178 19.1238 16.4163 18.628 15.9465L11.2956 9.00002L18.6281 2.05352C19.124 1.58374 19.124 0.8221 18.6281 0.352323C18.1323 -0.117441 17.3283 -0.117441 16.8325 0.352323L9.49996 7.29885L2.16759 0.352323Z"
              fill="#F4F4F4"
            />
          </svg>
        </button>
      </div>
      <textarea
        className="bg-transparent focus:outline-none w-4/5 mt-5"
        placeholder="Content"
        style={{ resize: "none" }}
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
    </div>
  );
}

export default Modal;
