function List({ data, deleteNote, editNote }) {
  return (
    <>
      {data.length > 0 ? (
        <div className="bg-slate-900 text-slate-200 p-5 rounded-xl">
          {data.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex justify-between cursor-pointer">
                  <div
                    className="flex flex-col  w-full"
                    onClick={() => editNote(item._id)}
                  >
                    <h2 className="text-lg font-bold">{item.title}</h2>
                    <p> {item.content}</p>
                  </div>
                  <button onClick={() => deleteNote(item._id)}>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.25 1.7C4.25 1.24913 4.42911 0.81673 4.74792 0.497918C5.06673 0.179107 5.49913 0 5.95 0H11.05C11.5009 0 11.9333 0.179107 12.2521 0.497918C12.5709 0.81673 12.75 1.24913 12.75 1.7V3.4H16.15C16.3754 3.4 16.5916 3.48955 16.751 3.64896C16.9104 3.80837 17 4.02457 17 4.25C17 4.47543 16.9104 4.69163 16.751 4.85104C16.5916 5.01045 16.3754 5.1 16.15 5.1H15.2414L14.5044 15.4207C14.4739 15.8496 14.282 16.251 13.9673 16.544C13.6526 16.8371 13.2386 17 12.8087 17H4.1905C3.76052 17 3.34651 16.8371 3.03185 16.544C2.7172 16.251 2.52528 15.8496 2.49475 15.4207L1.7595 5.1H0.85C0.624566 5.1 0.408365 5.01045 0.248959 4.85104C0.0895533 4.69163 0 4.47543 0 4.25C0 4.02457 0.0895533 3.80837 0.248959 3.64896C0.408365 3.48955 0.624566 3.4 0.85 3.4H4.25V1.7ZM5.95 3.4H11.05V1.7H5.95V3.4ZM3.4629 5.1L4.19135 15.3H12.8095L13.538 5.1H3.4629ZM6.8 6.8C7.02543 6.8 7.24163 6.88955 7.40104 7.04896C7.56045 7.20837 7.65 7.42457 7.65 7.65V12.75C7.65 12.9754 7.56045 13.1916 7.40104 13.351C7.24163 13.5104 7.02543 13.6 6.8 13.6C6.57457 13.6 6.35837 13.5104 6.19896 13.351C6.03955 13.1916 5.95 12.9754 5.95 12.75V7.65C5.95 7.42457 6.03955 7.20837 6.19896 7.04896C6.35837 6.88955 6.57457 6.8 6.8 6.8ZM10.2 6.8C10.4254 6.8 10.6416 6.88955 10.801 7.04896C10.9604 7.20837 11.05 7.42457 11.05 7.65V12.75C11.05 12.9754 10.9604 13.1916 10.801 13.351C10.6416 13.5104 10.4254 13.6 10.2 13.6C9.97457 13.6 9.75837 13.5104 9.59896 13.351C9.43955 13.1916 9.35 12.9754 9.35 12.75V7.65C9.35 7.42457 9.43955 7.20837 9.59896 7.04896C9.75837 6.88955 9.97457 6.8 10.2 6.8Z"
                        fill="#F4F4F4"
                      />
                    </svg>
                  </button>
                </div>
                {data.length !== 1 && index !== data.length - 1 ? (
                  <hr className="my-4 border-t border-t-[1px] border-gray-500" />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center">No Notes Yet!</div>
      )}
    </>
  );
}
export default List;
